/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const Create_User = /* GraphQL */ `
  mutation Create_User($input: CreateUserInput) {
    Create_User(input: $input)
  }
`;
export const Update_Service_Center = /* GraphQL */ `
  mutation Update_Service_Center($input: UpdateServiceCenterInput) {
    Update_Service_Center(input: $input)
  }
`;
export const Update_User = /* GraphQL */ `
  mutation Update_User($input: UpdateUserInput) {
    Update_User(input: $input)
  }
`;
export const Mark_Or_Change_Tickets_WIP_SubStage = /* GraphQL */ `
  mutation Mark_Or_Change_Tickets_WIP_SubStage(
    $input: MarkOrChangeTicketsWIPSubStageInput
  ) {
    Mark_Or_Change_Tickets_WIP_SubStage(input: $input)
  }
`;
export const Activate_Or_Deactivate_User = /* GraphQL */ `
  mutation Activate_Or_Deactivate_User($input: ActivateOrDeactivateUserInput) {
    Activate_Or_Deactivate_User(input: $input)
  }
`;
export const Create_Service_Type = /* GraphQL */ `
  mutation Create_Service_Type($input: CreateServiceTypeInput) {
    Create_Service_Type(input: $input)
  }
`;
export const Update_Service_Type = /* GraphQL */ `
  mutation Update_Service_Type($input: UpdateServiceTypeInput) {
    Update_Service_Type(input: $input)
  }
`;
export const Activate_Or_Deactivate_ServiceType = /* GraphQL */ `
  mutation Activate_Or_Deactivate_ServiceType(
    $input: ActivateOrDeactivateServiceTypeInput
  ) {
    Activate_Or_Deactivate_ServiceType(input: $input)
  }
`;
export const Create_Service_Products_Or_Center = /* GraphQL */ `
  mutation Create_Service_Products_Or_Center(
    $input: CreateServiceProductsOrCenterInput
  ) {
    Create_Service_Products_Or_Center(input: $input)
  }
`;
export const Create_Service_Based_Component = /* GraphQL */ `
  mutation Create_Service_Based_Component(
    $input: CreateServiceBasedComponentInput
  ) {
    Create_Service_Based_Component(input: $input)
  }
`;
export const Update_Component = /* GraphQL */ `
  mutation Update_Component($input: UpdateComponentInput) {
    Update_Component(input: $input)
  }
`;
export const Activate_Or_Deactivate_Component = /* GraphQL */ `
  mutation Activate_Or_Deactivate_Component(
    $input: ActivateOrDeactivateComponentInput
  ) {
    Activate_Or_Deactivate_Component(input: $input)
  }
`;
export const Create_Service_Center_For_Service = /* GraphQL */ `
  mutation Create_Service_Center_For_Service(
    $input: CreateServiceCenterForServiceInput
  ) {
    Create_Service_Center_For_Service(input: $input)
  }
`;
export const Activate_Or_Deactivate_Service_Center = /* GraphQL */ `
  mutation Activate_Or_Deactivate_Service_Center(
    $input: ActivateOrDeactivateServiceCenterInput
  ) {
    Activate_Or_Deactivate_Service_Center(input: $input)
  }
`;
export const Update_Service_Products_Or_Center = /* GraphQL */ `
  mutation Update_Service_Products_Or_Center($input: UpdateServiceInput) {
    Update_Service_Products_Or_Center(input: $input)
  }
`;
export const Activate_Or_Deactivate_Service = /* GraphQL */ `
  mutation Activate_Or_Deactivate_Service(
    $input: ActivateOrDeactivateServiceInput
  ) {
    Activate_Or_Deactivate_Service(input: $input)
  }
`;
export const UpdateS3CabinetDetails = /* GraphQL */ `
  mutation UpdateS3CabinetDetails($input: UpdateS3CabinetDetailsInput) {
    UpdateS3CabinetDetails(input: $input)
  }
`;
export const Create_Territory = /* GraphQL */ `
  mutation Create_Territory($input: CreateTerritoryInput) {
    Create_Territory(input: $input)
  }
`;
export const Update_Territory = /* GraphQL */ `
  mutation Update_Territory($input: UpdateTerritoryInput) {
    Update_Territory(input: $input)
  }
`;
export const Update_Enterprise_Details = /* GraphQL */ `
  mutation Update_Enterprise_Details($input: UpdateEnterpriseDetailsInput) {
    Update_Enterprise_Details(input: $input)
  }
`;
export const Activate_Or_Deactivate_Territory = /* GraphQL */ `
  mutation Activate_Or_Deactivate_Territory(
    $input: ActivateOrDeactivateTerritoryInput
  ) {
    Activate_Or_Deactivate_Territory(input: $input)
  }
`;
export const Create_Enterprise_Representative = /* GraphQL */ `
  mutation Create_Enterprise_Representative(
    $input: CreateEnterpriseRepresentativeInput
  ) {
    Create_Enterprise_Representative(input: $input)
  }
`;
export const Create_Partner_Representative = /* GraphQL */ `
  mutation Create_Partner_Representative(
    $input: CreatePartnerRepresentativeInput
  ) {
    Create_Partner_Representative(input: $input)
  }
`;
export const Update_Enterprise_Representative = /* GraphQL */ `
  mutation Update_Enterprise_Representative(
    $input: UpdateEnterpriseRepresentativeInput
  ) {
    Update_Enterprise_Representative(input: $input)
  }
`;
export const Update_Partner_Representative = /* GraphQL */ `
  mutation Update_Partner_Representative(
    $input: UpdatePartnerRepresentativeInput
  ) {
    Update_Partner_Representative(input: $input)
  }
`;
export const DeleteInvitedRepresentative = /* GraphQL */ `
  mutation DeleteInvitedRepresentative(
    $input: DeleteInvitedRepresentativeInput
  ) {
    DeleteInvitedRepresentative(input: $input)
  }
`;
export const Activate_Or_Deactivate_Representative = /* GraphQL */ `
  mutation Activate_Or_Deactivate_Representative(
    $input: ActivateOrDeactivateRepresentativeInput
  ) {
    Activate_Or_Deactivate_Representative(input: $input)
  }
`;
export const Create_Assign_Ticket = /* GraphQL */ `
  mutation Create_Assign_Ticket($input: CreateAssignTicketInput) {
    Create_Assign_Ticket(input: $input)
  }
`;
export const Create_Floating_Ticket = /* GraphQL */ `
  mutation Create_Floating_Ticket($input: CreateFloatingTicketInput) {
    Create_Floating_Ticket(input: $input)
  }
`;
export const ReassignOrOpenTheAssignedTicket = /* GraphQL */ `
  mutation ReassignOrOpenTheAssignedTicket(
    $input: ReassignOrOpenTheAssignedTicketInput
  ) {
    ReassignOrOpenTheAssignedTicket(input: $input)
  }
`;
export const Create_WIP_SubStages = /* GraphQL */ `
  mutation Create_WIP_SubStages($input: CreateWIPSubStagesInput) {
    Create_WIP_SubStages(input: $input)
  }
`;
export const Update_WIP_SubStage = /* GraphQL */ `
  mutation Update_WIP_SubStage($input: UpdateWIPSubStageInput) {
    Update_WIP_SubStage(input: $input)
  }
`;
export const Create_Customer_Category = /* GraphQL */ `
  mutation Create_Customer_Category($input: CreateCustomerCategoryInput) {
    Create_Customer_Category(input: $input)
  }
`;
export const Update_Customer_Category = /* GraphQL */ `
  mutation Update_Customer_Category($input: UpdateCustomerCategoryInput) {
    Update_Customer_Category(input: $input)
  }
`;
export const Activate_Or_Deactivate_Setting = /* GraphQL */ `
  mutation Activate_Or_Deactivate_Setting(
    $input: ActivateOrDeactivateSettingInput
  ) {
    Activate_Or_Deactivate_Setting(input: $input)
  }
`;
export const ReloadRepresentativeCredits = /* GraphQL */ `
  mutation ReloadRepresentativeCredits(
    $input: ReloadRepresentativeCreditsInput
  ) {
    ReloadRepresentativeCredits(input: $input)
  }
`;
export const ApproveOrRejectEnrolledTechnician = /* GraphQL */ `
  mutation ApproveOrRejectEnrolledTechnician(
    $input: ApproveOrRejectEnrolledTechnicianInput
  ) {
    ApproveOrRejectEnrolledTechnician(input: $input)
  }
`;
export const TransferOwnerShip = /* GraphQL */ `
  mutation TransferOwnerShip($input: TransferOwnerShipInput) {
    TransferOwnerShip(input: $input)
  }
`;
export const UpdateEmailType = /* GraphQL */ `
  mutation UpdateEmailType($input: UpdateEmailTypeInput) {
    UpdateEmailType(input: $input)
  }
`;
export const Create_Enterprise_Customer = /* GraphQL */ `
  mutation Create_Enterprise_Customer($input: CreateEnterpriseCustomerInput) {
    Create_Enterprise_Customer(input: $input)
  }
`;
export const Update_Enterprise_Customer = /* GraphQL */ `
  mutation Update_Enterprise_Customer($input: UpdateEnterpriseCustomerInput) {
    Update_Enterprise_Customer(input: $input)
  }
`;
export const Activate_Or_Deactivate_Enterprise_Customer = /* GraphQL */ `
  mutation Activate_Or_Deactivate_Enterprise_Customer(
    $input: ActivateOrDeactivateEnterpriseCustomerInput
  ) {
    Activate_Or_Deactivate_Enterprise_Customer(input: $input)
  }
`;
export const Change_Organization_Settings = /* GraphQL */ `
  mutation Change_Organization_Settings(
    $input: ChangeOrganizationSettingsInput
  ) {
    Change_Organization_Settings(input: $input)
  }
`;
export const Upload_Service_Catalogue = /* GraphQL */ `
  mutation Upload_Service_Catalogue($input: UploadServiceCatalogueInput) {
    Upload_Service_Catalogue(input: $input)
  }
`;
export const Upload_Service_Components = /* GraphQL */ `
  mutation Upload_Service_Components($input: UploadServiceComponentsInput) {
    Upload_Service_Components(input: $input)
  }
`;
export const Upload_Representative = /* GraphQL */ `
  mutation Upload_Representative($input: UploadRepresentativeInput) {
    Upload_Representative(input: $input)
  }
`;
export const Upload_Enterprise_Customers = /* GraphQL */ `
  mutation Upload_Enterprise_Customers($input: UploadEnterpriseCustomersInput) {
    Upload_Enterprise_Customers(input: $input)
  }
`;
export const Create_Scheduled_Ticket = /* GraphQL */ `
  mutation Create_Scheduled_Ticket($input: CreateScheduledTicketInput) {
    Create_Scheduled_Ticket(input: $input)
  }
`;
export const Stop_Or_Reset_Scheduled_Ticket = /* GraphQL */ `
  mutation Stop_Or_Reset_Scheduled_Ticket(
    $input: StopOrResetScheduledTicketInput
  ) {
    Stop_Or_Reset_Scheduled_Ticket(input: $input)
  }
`;
export const Create_Multiple_Locations_Of_Customer = /* GraphQL */ `
  mutation Create_Multiple_Locations_Of_Customer(
    $input: CreateMultipleLocationsOfCustomerInput
  ) {
    Create_Multiple_Locations_Of_Customer(input: $input)
  }
`;
export const Delete_Customers_Secondary_Location = /* GraphQL */ `
  mutation Delete_Customers_Secondary_Location(
    $input: DeleteCustomersSecondaryLocationInput
  ) {
    Delete_Customers_Secondary_Location(input: $input)
  }
`;
export const ReOpen_Closed_Ticket = /* GraphQL */ `
  mutation ReOpen_Closed_Ticket($input: ReOpenClosedTicketInput) {
    ReOpen_Closed_Ticket(input: $input)
  }
`;
export const CreateTicketClosureRemarks = /* GraphQL */ `
  mutation CreateTicketClosureRemarks($input: CreateTicketClosureRemarksInput) {
    CreateTicketClosureRemarks(input: $input)
  }
`;
export const UpdateTicketClosureRemarks = /* GraphQL */ `
  mutation UpdateTicketClosureRemarks($input: UpdateTicketClosureRemarksInput) {
    UpdateTicketClosureRemarks(input: $input)
  }
`;
export const CreatePartnerUser = /* GraphQL */ `
  mutation CreatePartnerUser($input: CreatePartnerUserInput) {
    CreatePartnerUser(input: $input)
  }
`;
export const UpdatePartnerUsers = /* GraphQL */ `
  mutation UpdatePartnerUsers($input: UpdateEnterpriseRepresentativeInput) {
    UpdatePartnerUsers(input: $input)
  }
`;
export const ActivateOrDeactivatePartnerUser = /* GraphQL */ `
  mutation ActivateOrDeactivatePartnerUser(
    $input: ActivateOrDeactivatePartnerUserInput
  ) {
    ActivateOrDeactivatePartnerUser(input: $input)
  }
`;
export const DiscardTicket = /* GraphQL */ `
  mutation DiscardTicket($input: DiscardTicketInput) {
    DiscardTicket(input: $input)
  }
`;
export const CreateDealerUser = /* GraphQL */ `
  mutation CreateDealerUser($input: CreateEnterpriseDealersInput) {
    CreateDealerUser(input: $input)
  }
`;
export const UpdateDealerUser = /* GraphQL */ `
  mutation UpdateDealerUser($input: UpdateEnterpriseDealersInput) {
    UpdateDealerUser(input: $input)
  }
`;
export const ActivateOrDeactivateDealerUser = /* GraphQL */ `
  mutation ActivateOrDeactivateDealerUser(
    $input: ActivateOrDeactivateDealersInput
  ) {
    ActivateOrDeactivateDealerUser(input: $input)
  }
`;
export const CloseTicket = /* GraphQL */ `
  mutation CloseTicket($input: CloseTicketInput) {
    CloseTicket(input: $input)
  }
`;
export const CreateProblemDescription = /* GraphQL */ `
  mutation CreateProblemDescription($input: CreateProblemDescriptionInput) {
    CreateProblemDescription(input: $input)
  }
`;
export const UpdateProblemDescription = /* GraphQL */ `
  mutation UpdateProblemDescription($input: UpdateProblemDescriptionInput) {
    UpdateProblemDescription(input: $input)
  }
`;
export const TagComponentsToService = /* GraphQL */ `
  mutation TagComponentsToService($input: TagComponentsToServiceInput) {
    TagComponentsToService(input: $input)
  }
`;
export const IncrementComponentsCount = /* GraphQL */ `
  mutation IncrementComponentsCount($input: IncrementComponentsCountInput) {
    IncrementComponentsCount(input: $input)
  }
`;
export const TransferComponents = /* GraphQL */ `
  mutation TransferComponents($input: TransferComponentsInput) {
    TransferComponents(input: $input)
  }
`;
export const CreateAMCTypes = /* GraphQL */ `
  mutation CreateAMCTypes($input: CreateAMCTypesInput) {
    CreateAMCTypes(input: $input)
  }
`;
export const UpdateAMCTypes = /* GraphQL */ `
  mutation UpdateAMCTypes($input: UpdateAMCTypesInput) {
    UpdateAMCTypes(input: $input)
  }
`;
export const GenerateQuotation = /* GraphQL */ `
  mutation GenerateQuotation($input: GenerateQuotationInput) {
    GenerateQuotation(input: $input)
  }
`;
export const UpdateQuotation = /* GraphQL */ `
  mutation UpdateQuotation($input: UpdateQuotationInput) {
    UpdateQuotation(input: $input)
  }
`;
export const GenerateTicketPDF = /* GraphQL */ `
  mutation GenerateTicketPDF($input: GenerateTicketPDFInput) {
    GenerateTicketPDF(input: $input)
  }
`;
export const UploadScheduledServices = /* GraphQL */ `
  mutation UploadScheduledServices($input: UploadScheduledServicesInput) {
    UploadScheduledServices(input: $input)
  }
`;
export const TagProductsToCustomer = /* GraphQL */ `
  mutation TagProductsToCustomer($input: TagProductsToCustomerInput) {
    TagProductsToCustomer(input: $input)
  }
`;
export const CreateProductBasedSerialNumbers = /* GraphQL */ `
  mutation CreateProductBasedSerialNumbers(
    $input: CreateProductBasedSerialNumbersInput
  ) {
    CreateProductBasedSerialNumbers(input: $input)
  }
`;
export const UpdateProductBasedSerialNumbers = /* GraphQL */ `
  mutation UpdateProductBasedSerialNumbers(
    $input: UpdateProductBasedSerialNumbersInput
  ) {
    UpdateProductBasedSerialNumbers(input: $input)
  }
`;
export const TagCustomersToDealers = /* GraphQL */ `
  mutation TagCustomersToDealers($input: TagCustomersToDealersInput) {
    TagCustomersToDealers(input: $input)
  }
`;
export const UploadProductSerialNumbers = /* GraphQL */ `
  mutation UploadProductSerialNumbers($input: UploadServiceSerialNumbersInput) {
    UploadProductSerialNumbers(input: $input)
  }
`;
export const TagRequiredModuleToTerritories = /* GraphQL */ `
  mutation TagRequiredModuleToTerritories(
    $input: TagRequiredModuleToTerritoriesInput
  ) {
    TagRequiredModuleToTerritories(input: $input)
  }
`;
export const UpdateUserDetails = /* GraphQL */ `
  mutation UpdateUserDetails($input: UpdateUserDetailsInput) {
    UpdateUserDetails(input: $input)
  }
`;
export const CreateForm = /* GraphQL */ `
  mutation CreateForm($input: CreateFormInput) {
    CreateForm(input: $input)
  }
`;
export const UpdateForm = /* GraphQL */ `
  mutation UpdateForm($input: UpdateFormInput) {
    UpdateForm(input: $input)
  }
`;
export const ActivateOrDeactiveForm = /* GraphQL */ `
  mutation ActivateOrDeactiveForm($input: ActivateOrDeactiveFormInput) {
    ActivateOrDeactiveForm(input: $input)
  }
`;
export const CreateBusinessOrIndividualCustomer = /* GraphQL */ `
  mutation CreateBusinessOrIndividualCustomer(
    $input: CreateBusinessOrIndividualCustomerInput
  ) {
    CreateBusinessOrIndividualCustomer(input: $input)
  }
`;
export const UpdateBusinessOrIndividualCustomer = /* GraphQL */ `
  mutation UpdateBusinessOrIndividualCustomer(
    $input: UpdateBusinessOrIndividualCustomerInput
  ) {
    UpdateBusinessOrIndividualCustomer(input: $input)
  }
`;
export const ActivateOrDeactivateSerialNumber = /* GraphQL */ `
  mutation ActivateOrDeactivateSerialNumber(
    $input: ActivateOrDeactivateSerialNumbeInput
  ) {
    ActivateOrDeactivateSerialNumber(input: $input)
  }
`;
export const ActivateOrDeactivateBusinessIndividualCustomer = /* GraphQL */ `
  mutation ActivateOrDeactivateBusinessIndividualCustomer(
    $input: ActivateOrDeactivateBusinessIndividualCustomerinput
  ) {
    ActivateOrDeactivateBusinessIndividualCustomer(input: $input)
  }
`;
export const CreateTicketEscalationNotification = /* GraphQL */ `
  mutation CreateTicketEscalationNotification(
    $input: CreateTicketEscalationNotificationInput
  ) {
    CreateTicketEscalationNotification(input: $input)
  }
`;
export const UpdateTicketEscalationNotification = /* GraphQL */ `
  mutation UpdateTicketEscalationNotification(
    $input: UpdateTicketEscalationNotificationInput
  ) {
    UpdateTicketEscalationNotification(input: $input)
  }
`;
export const TransferProductFleetToServiceCenter = /* GraphQL */ `
  mutation TransferProductFleetToServiceCenter(
    $input: TransferProductFleetToServiceCenterInput
  ) {
    TransferProductFleetToServiceCenter(input: $input)
  }
`;
export const CreateAssignedTicketV2 = /* GraphQL */ `
  mutation CreateAssignedTicketV2($input: CreateAssignTicketV2Input) {
    CreateAssignedTicketV2(input: $input)
  }
`;
export const CreateDesignation = /* GraphQL */ `
  mutation CreateDesignation($input: CreateDesignationInput) {
    CreateDesignation(input: $input)
  }
`;
export const UpdateDesignation = /* GraphQL */ `
  mutation UpdateDesignation($input: UpdateDesignationInput) {
    UpdateDesignation(input: $input)
  }
`;
export const TakeActionOnWorkFlow = /* GraphQL */ `
  mutation TakeActionOnWorkFlow($input: TakeActionOnWorkFlowInput) {
    TakeActionOnWorkFlow(input: $input)
  }
`;
export const TicketInteraction = /* GraphQL */ `
  mutation TicketInteraction($input: TicketInteractionInput) {
    TicketInteraction(input: $input)
  }
`;
export const EditOrUntagCustomerTaggedProduct = /* GraphQL */ `
  mutation EditOrUntagCustomerTaggedProduct(
    $input: EditOrUntagCustomerTaggedProductInput
  ) {
    EditOrUntagCustomerTaggedProduct(input: $input)
  }
`;
export const TagServiceCentersToTerritory = /* GraphQL */ `
  mutation TagServiceCentersToTerritory(
    $input: TagServiceCentersToTerritoryInput
  ) {
    TagServiceCentersToTerritory(input: $input)
  }
`;
export const CustomerBulkUpload = /* GraphQL */ `
  mutation CustomerBulkUpload($input: CustomerBulkUploadInput) {
    CustomerBulkUpload(input: $input)
  }
`;
export const ActivateOrDeactivateDesignationsRoles = /* GraphQL */ `
  mutation ActivateOrDeactivateDesignationsRoles(
    $input: ActivateOrDeactivateDesignationsRolesInput
  ) {
    ActivateOrDeactivateDesignationsRoles(input: $input)
  }
`;
export const CreateProductFleetStatusType = /* GraphQL */ `
  mutation CreateProductFleetStatusType(
    $input: CreateProductFleetStatusTypeInput
  ) {
    CreateProductFleetStatusType(input: $input)
  }
`;
export const UpdateProductFleetStatusType = /* GraphQL */ `
  mutation UpdateProductFleetStatusType(
    $input: UpdateProductFleetStatusTypeInput
  ) {
    UpdateProductFleetStatusType(input: $input)
  }
`;
export const DeleteProductFleetStatusType = /* GraphQL */ `
  mutation DeleteProductFleetStatusType(
    $input: DeleteProductFleetStatusTypeInput
  ) {
    DeleteProductFleetStatusType(input: $input)
  }
`;
export const ActivateOrDeactiveProductFleetStatusType = /* GraphQL */ `
  mutation ActivateOrDeactiveProductFleetStatusType(
    $input: ActivateOrDeactiveProductFleetStatusTypeInput
  ) {
    ActivateOrDeactiveProductFleetStatusType(input: $input)
  }
`;
export const UpdateVisitDateAndTimeToTicket = /* GraphQL */ `
  mutation UpdateVisitDateAndTimeToTicket(
    $input: UpdateVisitDateAndTimeToTicketInput
  ) {
    UpdateVisitDateAndTimeToTicket(input: $input)
  }
`;
export const ApproveOrRejectUserRequestedComponent = /* GraphQL */ `
  mutation ApproveOrRejectUserRequestedComponent(
    $input: ApproveOrRejectUserRequestedComponent
  ) {
    ApproveOrRejectUserRequestedComponent(input: $input)
  }
`;
export const ReCreateTicket = /* GraphQL */ `
  mutation ReCreateTicket($input: CreateAssignTicketV2Input) {
    ReCreateTicket(input: $input)
  }
`;
export const GenerateS3Url = /* GraphQL */ `
  mutation GenerateS3Url($input: GenerateS3UrlInput) {
    GenerateS3Url(input: $input)
  }
`;
export const AcceptOrRejectTicket = /* GraphQL */ `
  mutation AcceptOrRejectTicket($input: AcceptOrRejectTicketInput) {
    AcceptOrRejectTicket(input: $input)
  }
`;
export const DeleteInvalidCustomer = /* GraphQL */ `
  mutation DeleteInvalidCustomer($input: DeleteInvalidCustomerInput) {
    DeleteInvalidCustomer(input: $input)
  }
`;
export const DeleteInvalidSerialNumber = /* GraphQL */ `
  mutation DeleteInvalidSerialNumber($input: DeleteInvalidSerialNumberInput) {
    DeleteInvalidSerialNumber(input: $input)
  }
`;
export const GenerateExcelForOrganiztionOfMonthlyActiveTicket = /* GraphQL */ `
  mutation GenerateExcelForOrganiztionOfMonthlyActiveTicket(
    $input: GenerateExcelForOrganiztionOfMonthlyActiveTicketInput
  ) {
    GenerateExcelForOrganiztionOfMonthlyActiveTicket(input: $input)
  }
`;
export const GenerateExcelForOrganiztionOfMonthlyClosedTicket = /* GraphQL */ `
  mutation GenerateExcelForOrganiztionOfMonthlyClosedTicket(
    $input: GenerateExcelForOrganiztionOfMonthlyActiveTicketInput
  ) {
    GenerateExcelForOrganiztionOfMonthlyClosedTicket(input: $input)
  }
`;
export const TransferProductFleet = /* GraphQL */ `
  mutation TransferProductFleet($input: TransferProductFleetInput) {
    TransferProductFleet(input: $input)
  }
`;
export const ApproveOrRejectProductFleet = /* GraphQL */ `
  mutation ApproveOrRejectProductFleet(
    $input: ApproveOrRejectProductFleetInput
  ) {
    ApproveOrRejectProductFleet(input: $input)
  }
`;
export const UpdateInvalidUser = /* GraphQL */ `
  mutation UpdateInvalidUser($input: UpdateInvalidUserInput) {
    UpdateInvalidUser(input: $input)
  }
`;
export const ApproveOrRejectProductFleetRequest = /* GraphQL */ `
  mutation ApproveOrRejectProductFleetRequest(
    $input: ApproveOrRejectProductFleetRequestInput
  ) {
    ApproveOrRejectProductFleetRequest(input: $input)
  }
`;
export const CreateAdditionalPart = /* GraphQL */ `
  mutation CreateAdditionalPart($input: CreateAdditionalPartInput) {
    CreateAdditionalPart(input: $input)
  }
`;
export const UpdateAdditionalPart = /* GraphQL */ `
  mutation UpdateAdditionalPart($input: UpdateAdditionalPartInput) {
    UpdateAdditionalPart(input: $input)
  }
`;
export const ActivateORDeactivateAdditionalPart = /* GraphQL */ `
  mutation ActivateORDeactivateAdditionalPart(
    $input: ActivateORDeactivateAdditionalPartInput
  ) {
    ActivateORDeactivateAdditionalPart(input: $input)
  }
`;
export const UpdateTrackingDetailsOfComponent = /* GraphQL */ `
  mutation UpdateTrackingDetailsOfComponent(
    $input: UpdateTrackingDetailsOfComponentInput
  ) {
    UpdateTrackingDetailsOfComponent(input: $input)
  }
`;
export const CreateTerritoryType = /* GraphQL */ `
  mutation CreateTerritoryType($input: CreateTerritoryTypeInput) {
    CreateTerritoryType(input: $input)
  }
`;
export const UpdateTerritoryTypes = /* GraphQL */ `
  mutation UpdateTerritoryTypes($input: UpdateTerritoryTypesInput) {
    UpdateTerritoryTypes(input: $input)
  }
`;
export const ActivateORDeactivateTerritoryType = /* GraphQL */ `
  mutation ActivateORDeactivateTerritoryType(
    $input: ActivateORDeactivateTerritoryTypeInput
  ) {
    ActivateORDeactivateTerritoryType(input: $input)
  }
`;
export const ReAssignPartiallyClosedTicket = /* GraphQL */ `
  mutation ReAssignPartiallyClosedTicket(
    $input: ReassignOrOpenTheAssignedTicketInput
  ) {
    ReAssignPartiallyClosedTicket(input: $input)
  }
`;
export const TestingApprovalComponent = /* GraphQL */ `
  mutation TestingApprovalComponent(
    $input: ApproveOrRejectUserRequestedComponent
  ) {
    TestingApprovalComponent(input: $input)
  }
`;
export const RejectRequestedProductToTag = /* GraphQL */ `
  mutation RejectRequestedProductToTag(
    $input: RejectRequestedProductToTagInput
  ) {
    RejectRequestedProductToTag(input: $input)
  }
`;
export const ApproveRequestedProductToTagCustomer = /* GraphQL */ `
  mutation ApproveRequestedProductToTagCustomer(
    $input: ApproveRequestedProductToTagCustomerInput
  ) {
    ApproveRequestedProductToTagCustomer(input: $input)
  }
`;
export const CreateVendor = /* GraphQL */ `
  mutation CreateVendor($input: CreateVendorInput) {
    CreateVendor(input: $input)
  }
`;
export const UpdateVendorDetails = /* GraphQL */ `
  mutation UpdateVendorDetails($input: UpdateVendorDetailsInput) {
    UpdateVendorDetails(input: $input)
  }
`;
export const AddOrRemovePincodeFromTerritory = /* GraphQL */ `
  mutation AddOrRemovePincodeFromTerritory(
    $input: AddOrRemovePincodeFromTerritoryInput
  ) {
    AddOrRemovePincodeFromTerritory(input: $input)
  }
`;
export const ActivateOrDeactivateTerritoryOrPincode = /* GraphQL */ `
  mutation ActivateOrDeactivateTerritoryOrPincode(
    $input: ActivateOrDeactivateTerritoryOrPincodeInput
  ) {
    ActivateOrDeactivateTerritoryOrPincode(input: $input)
  }
`;
export const GenerateExcelForMRF = /* GraphQL */ `
  mutation GenerateExcelForMRF($input: GenerateExcelForMRFInput) {
    GenerateExcelForMRF(input: $input)
  }
`;
export const GenerateExcelForPartiallyClosedTicket = /* GraphQL */ `
  mutation GenerateExcelForPartiallyClosedTicket(
    $input: GenerateExcelForPartiallyClosedTicketInput
  ) {
    GenerateExcelForPartiallyClosedTicket(input: $input)
  }
`;
export const CreateMRFTicket = /* GraphQL */ `
  mutation CreateMRFTicket($input: CreateMRFTicketInput) {
    CreateMRFTicket(input: $input)
  }
`;
export const UpdateGenerateExcelReportParams = /* GraphQL */ `
  mutation UpdateGenerateExcelReportParams(
    $input: UpdateGenerateExcelReportParamsInput
  ) {
    UpdateGenerateExcelReportParams(input: $input)
  }
`;
export const TransferRepresentative = /* GraphQL */ `
  mutation TransferRepresentative($input: TransferRepresentativeInput) {
    TransferRepresentative(input: $input)
  }
`;
export const UpdateCustomerDetailsInTicket = /* GraphQL */ `
  mutation UpdateCustomerDetailsInTicket(
    $input: UpdateCustomerDetailsInTicketInput
  ) {
    UpdateCustomerDetailsInTicket(input: $input)
  }
`;
export const UpdateWorkFlowStatusToApproved = /* GraphQL */ `
  mutation UpdateWorkFlowStatusToApproved(
    $input: UpdateWorkFlowStatusToApprovedInput
  ) {
    UpdateWorkFlowStatusToApproved(input: $input)
  }
`;
export const ApprovalForEditProductDetails = /* GraphQL */ `
  mutation ApprovalForEditProductDetails(
    $input: ApprovalForEditProductDetailsInput
  ) {
    ApprovalForEditProductDetails(input: $input)
  }
`;
export const GenerateQuotationForComponent = /* GraphQL */ `
  mutation GenerateQuotationForComponent(
    $input: GenerateQuotationForComponentInput
  ) {
    GenerateQuotationForComponent(input: $input)
  }
`;
export const SendQuotationPdfToCustomer = /* GraphQL */ `
  mutation SendQuotationPdfToCustomer($input: SendQuotationPdfToCustomerInput) {
    SendQuotationPdfToCustomer(input: $input)
  }
`;
export const SendQuotationToApprovals = /* GraphQL */ `
  mutation SendQuotationToApprovals($input: SendQuotationToApprovalsInput) {
    SendQuotationToApprovals(input: $input)
  }
`;
export const ApproveOrRejectQuotation = /* GraphQL */ `
  mutation ApproveOrRejectQuotation($input: ApproveOrRejectQuotationInput) {
    ApproveOrRejectQuotation(input: $input)
  }
`;
export const UpdateQuotationForPaymentDetails = /* GraphQL */ `
  mutation UpdateQuotationForPaymentDetails(
    $input: UpdateQuotationForPaymentDetailsInput
  ) {
    UpdateQuotationForPaymentDetails(input: $input)
  }
`;
export const AproveOrRejectQuotationComponentRequest = /* GraphQL */ `
  mutation AproveOrRejectQuotationComponentRequest(
    $input: AproveOrRejectQuotationComponentRequestInput
  ) {
    AproveOrRejectQuotationComponentRequest(input: $input)
  }
`;
export const UpdateLabourQuotationPayment = /* GraphQL */ `
  mutation UpdateLabourQuotationPayment(
    $input: UpdateLabourQuotationPaymentInput
  ) {
    UpdateLabourQuotationPayment(input: $input)
  }
`;
export const TagProductOrComponentToVendor = /* GraphQL */ `
  mutation TagProductOrComponentToVendor(
    $input: TagProductOrComponentToVendorInput
  ) {
    TagProductOrComponentToVendor(input: $input)
  }
`;
export const ActivateOrDeactivateTaggedProductOrComponentOfVendor = /* GraphQL */ `
  mutation ActivateOrDeactivateTaggedProductOrComponentOfVendor(
    $input: ActivateOrDeactivateTaggedProductOrComponentOfVendorInput
  ) {
    ActivateOrDeactivateTaggedProductOrComponentOfVendor(input: $input)
  }
`;
export const ActivateOrDeactivatePurchaseOrderToVendor = /* GraphQL */ `
  mutation ActivateOrDeactivatePurchaseOrderToVendor(
    $input: ActivateOrDeactivatePurchaseOrderToVendorInput
  ) {
    ActivateOrDeactivatePurchaseOrderToVendor(input: $input)
  }
`;
export const SharePurchaseOrderToVendor = /* GraphQL */ `
  mutation SharePurchaseOrderToVendor($input: SharePurchaseOrderToVendorInput) {
    SharePurchaseOrderToVendor(input: $input)
  }
`;
export const editTaggedProductOrComponentOfVendor = /* GraphQL */ `
  mutation EditTaggedProductOrComponentOfVendor(
    $input: editTaggedProductOrComponentOfVendorInput
  ) {
    editTaggedProductOrComponentOfVendor(input: $input)
  }
`;
export const GeneratePurchaseOrder = /* GraphQL */ `
  mutation GeneratePurchaseOrder($input: GeneratePurchaseOrderInput) {
    GeneratePurchaseOrder(input: $input)
  }
`;
export const UpdatePurchaseOrderPaymentDetails = /* GraphQL */ `
  mutation UpdatePurchaseOrderPaymentDetails(
    $input: UpdatePurchaseOrderPaymentDetailsInput
  ) {
    UpdatePurchaseOrderPaymentDetails(input: $input)
  }
`;
export const UpdatePurchaseOrderClosedDetails = /* GraphQL */ `
  mutation UpdatePurchaseOrderClosedDetails(
    $input: UpdatePurchaseOrderClosedDetailsInput
  ) {
    UpdatePurchaseOrderClosedDetails(input: $input)
  }
`;
export const UpdatePincodesAsUpcountry = /* GraphQL */ `
  mutation UpdatePincodesAsUpcountry($input: UpdatePincodesAsUpcountryInput) {
    UpdatePincodesAsUpcountry(input: $input)
  }
`;
export const DeleteCabinetS3Files = /* GraphQL */ `
  mutation DeleteCabinetS3Files($input: DeleteCabinetS3FilesInput) {
    DeleteCabinetS3Files(input: $input)
  }
`;
export const FgrApproval = /* GraphQL */ `
  mutation FgrApproval($input: FgrApprovalInput) {
    FgrApproval(input: $input)
  }
`;
export const GenerateFGR = /* GraphQL */ `
  mutation GenerateFGR($input: GenerateFGRInput) {
    GenerateFGR(input: $input)
  }
`;
export const ReOpenFGR = /* GraphQL */ `
  mutation ReOpenFGR($input: GenerateFGRInput) {
    ReOpenFGR(input: $input)
  }
`;
export const CreatePincodeType = /* GraphQL */ `
  mutation CreatePincodeType($input: CreatePincodeTypeInput) {
    CreatePincodeType(input: $input)
  }
`;
export const EditPincodeType = /* GraphQL */ `
  mutation EditPincodeType($input: CreatePincodeTypeInput) {
    EditPincodeType(input: $input)
  }
`;
export const GenerateOtpForLogin = /* GraphQL */ `
  mutation GenerateOtpForLogin($input: GenerateOtpForLoginInput) {
    GenerateOtpForLogin(input: $input)
  }
`;
export const VerifyOtpForLogin = /* GraphQL */ `
  mutation VerifyOtpForLogin($input: VerifyOtpForLoginInput) {
    VerifyOtpForLogin(input: $input)
  }
`;
export const UnMapPincodesFromTerritoryType = /* GraphQL */ `
  mutation UnMapPincodesFromTerritoryType(
    $input: UnMapPincodesFromTerritoryTypeInput
  ) {
    UnMapPincodesFromTerritoryType(input: $input)
  }
`;
export const RejectRFSTicket = /* GraphQL */ `
  mutation RejectRFSTicket($input: RejectRFSTicketInput) {
    RejectRFSTicket(input: $input)
  }
`;
export const UpdateFGRClaimStatus = /* GraphQL */ `
  mutation UpdateFGRClaimStatus($input: UpdateFGRClaimStatusInput) {
    UpdateFGRClaimStatus(input: $input)
  }
`;
export const CreateDealerProducts = /* GraphQL */ `
  mutation CreateDealerProducts($input: CreateProductBasedSerialNumbersInput) {
    CreateDealerProducts(input: $input)
  }
`;
export const EditFGR = /* GraphQL */ `
  mutation EditFGR($input: EditFGRInput) {
    EditFGR(input: $input)
  }
`;
export const TagProductToDealerOrPartnerCustomer = /* GraphQL */ `
  mutation TagProductToDealerOrPartnerCustomer(
    $input: TagProductToDealerOrPartnerCustomerInput
  ) {
    TagProductToDealerOrPartnerCustomer(input: $input)
  }
`;
export const UpdateWarrantyDetailsInTicket = /* GraphQL */ `
  mutation UpdateWarrantyDetailsInTicket(
    $input: UpdateWarrantyDetailsInTicketInput
  ) {
    UpdateWarrantyDetailsInTicket(input: $input)
  }
`;
export const CreateDealerOrPartnerRFS = /* GraphQL */ `
  mutation CreateDealerOrPartnerRFS($input: CreateDealerOrPartnerRFSInput) {
    CreateDealerOrPartnerRFS(input: $input)
  }
`;
export const RejectDealerOrPartnerRFSTicket = /* GraphQL */ `
  mutation RejectDealerOrPartnerRFSTicket(
    $input: RejectDealerOrPartnerRFSTicketInput
  ) {
    RejectDealerOrPartnerRFSTicket(input: $input)
  }
`;
